import React, {useState, useEffect} from "react"
import {Container,Row,Col,Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import ValDayImg from "../assests/images/img2.jpg";
import AddImage from "../assests/images/add_image_icon.svg";

import {config} from '../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../queries/common_use_query";

const ValuationDay = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('valuation-day-agenda');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

  return(

    <>
      <SEO title="CreatePresentation" description="CreatePresentation" />

      <div className="dashboard-wrapper">
        <LeftNav page_name="Create Presentation" />
        {/* sidebar */}

        <div className="dashboard-content">
        <Container>
            <div className="content-wrapper section-wrapper">
                <h2>{article_data && article_data.Title}</h2>

                {article_data && article_data.Content && <p className="fig-content font_20">{HTMLReactParser(article_data.Content)}</p>}

                <ul className="valDayList">
                    {
                        article_data && article_data.Modules.map((valuation, index) => {
                            return(
                                <li>
                                    <div className="ValDayImg">
                                        { valuation.Upload_Image && <img src={strapi_url+''+valuation.Upload_Image.url} alt="" /> }
                                    </div>
                                    
                                    <div className="ValDayText">
                                        <h4>{valuation.Title}</h4>
                                        <p className="fig-content font_18">{valuation.Content && HTMLReactParser(valuation.Content)}</p>
                                    </div>                        
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

            <div className="seller_config_btns">
                <div className="float-left">                    
                    <Button variant="secondary" className="back-btn" type="submit" onClick={() => {navigate(config.create_preval_presentation)}}>back</Button>
                </div>
                <div className="float-right">                    
                    <Button variant="secondary" type="submit">reset</Button>
                    <Button variant="primary" type="submit" onClick={() => navigate(config.create_preval_presentation)}>save</Button>
                </div>
            </div>
        </Container>
        {/* container */}
      </div>
      {/* dashboard-content */}
      </div>
    </>
  )

}

export default ValuationDay